




import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import Region from '@/model/region'
import { DataTableHeader } from 'node_modules/vuetify/types';

@Component({
  components: {

  },
})
export default class Home extends Vue {


  created(){
  }
}
