



















































































































 
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import UserContext from '@/model/UserContext';
import LoginPage from '@/components/Wireframe/LoginPageView.vue'


@Component({
  components: {
    LoginPage
  }
})
export default class App extends Vue {

  @Getter userContext: UserContext;
  @Action fetchUserContext
  @Action logout

  private drawer: boolean = true;

  created(){
    this.fetchUserContext({});
  }
}

