import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import store from '..'
import API from '../api'

Vue.use(Vuex)

export default {
  state: {
      regions: []
  },
  getters: {
    getRegions: state => state.regions,
  },
  mutations: {
    setRegions: (state, value) => (state.regions = value),
  },
  actions: {
    async fetchRegions( {commit} ){
      await axios.get(API.URL_REGIONS(0), {
        withCredentials: true,
      })
      .then(response => {
        commit('setRegions', response.data);
      })
      .catch(error => {
        window.console.error(error);
      });
    },
  },
  modules: {
  }
}
