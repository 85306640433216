export default class API 
{
    static BASE_URL: string = `https://api-1.busdb.net/api`;
    static URL_LOGIN = () => `${API.BASE_URL}/login`;
    static URL_LOGOUT = () => `${API.BASE_URL}/login?logout`;

    static URL_REGIONS = (id) => `${API.BASE_URL}/regions/${id}`;
    static URL_REGION = (id) => `${API.BASE_URL}/Region/${id}`;
    static URL_VEHICLE = (id) => `${API.BASE_URL}/Vehicle/${id}`;
    static URL_OPERATOR = (id) => `${API.BASE_URL}/Operator/${id}`;
    static URL_USER = (id) => `${API.BASE_URL}/User/${id}`;
    static URL_MODEL = (id) => `${API.BASE_URL}/Model/${id}`;
}