import UserContext from '@/model/UserContext'
import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import store from '..'
import API from '../api'

Vue.use(Vuex)

export class SystemState{
  userContext: UserContext;
}

export default {
  state: {
      userContext: null
  },
  getters: {
    userContext: state => state.userContext,
  },
  mutations: {
    setUserContext: (state: SystemState, value: UserContext) => (state.userContext = value),
  },
  actions: {
    async fetchUserContext( {commit}, login ){
      console.log('fetching user context:', login);
      await axios.post(API.URL_LOGIN(), login, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      })
      .then(response => {
        if(response.data != ''){
          console.log('got user context:', response.data);
          commit('setUserContext', response.data);
        }
      })
      .catch(error => {
        window.console.log('Could not log in.');
      });
    },
    async logout( {commit} ){
      await axios.get(API.URL_LOGOUT())
      .then(response => {
        commit('setUserContext', null);
      })
      .catch(error => {
        window.console.error(error);
      });
    },
  },
  modules: {
  }
}
