import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },
      theme: {
        themes: {
          light: {
            primary: '#385D8A', // mittelblau // alt: #E53935
            secondary: '#1F87D1', // dunkelgrau // alt: #FFCDD2
          },
        },
      },
});
