













































 
import { Action } from 'vuex-class';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class LoginPageView extends Vue 
{
  @Action fetchUserContext

  showPassword: boolean = false;
  rememberMe: boolean = false;
  email: string = '';
  password: string = '';

  test(){
    console.log(this.rememberMe);
  }

  login(){
    let data: FormData = new FormData();
    data.append('username', this.email);
    data.append('password', this.password);

    if(this.rememberMe){
      data.append('keep_logged_in', 'true');
    }

    this.fetchUserContext(data);
  }

}

